
export default [
  // these are here to create route aliases to improve URLS for user dashboard
  { name: "user", path: "/user", redirect: { name: "user-dashboard-profile" } },
  {
    name: "user-dashboard-dashboard",
    path: "/user/dashboard/dashboard",
    redirect: { name: "user-dashboard-profile" },
  },
  // { path: "/user/dashboard", redirect: { name: "user-dashboard-profile" } },
];
